import React from 'react'
import Header from '../components/Header'


export const LayoutOne=(props)=>{
  return (
    <>
      <div>
        <Header/>
        {props.children}
      </div>
    </>
  )
}