import React,{useState} from 'react'


function NftCard(props) {
  const {nft,viewNft} = props;


  return (
    <>
      <div className="col-md-3 my-3">
          <div className="card nft-card" onClick={()=>{viewNft(nft)}}>
              <img src={nft.image} className="card-img-top img-fluid" alt={nft.name}/>
              <div className="card-body">
                  <h5 className="card-title text-center nft-card-title">{nft.name}</h5>
                  <p className="card-text text-center nft-card-subtitle">{nft.attributes.find((v)=>v.trait_type=="Rarity").value}</p>
              </div>
             
          </div>
      </div>

     
    </>
  )
}

export default NftCard