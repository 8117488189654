import React,{useState,useEffect} from 'react'
import { connect } from 'react-redux';

import NFTProjectInfo from '../../services/constants/projects/nft';
import { userLogin,mintNftsWithEth,mintNftsWithToken,resetMint } from "../../services/actions"


function Homepage(props) {
  const [mintProcess, setMintProcess] = useState(false);
  const [mintNumber, setMintNumber] = useState(1);

  const [error, setError] = useState("");
  const { user,nftInfo } = props;

  const price = nftInfo.price ? nftInfo.price/(10**18) : "";

  useEffect(() => {
    props.resetMint();
  }, [])

  const setMintCount = (e) => {
    let num = e.target.value;
    if (num > 0) {
      setError("");
      setMintNumber(num);
    }
  }

  const mintTokenBtn = (type)=>{
    setError("");
    setMintProcess(true)
    if(type == 'eth'){
      props.mintNftsWithEth(mintNumber,user.data.eth_address,(data)=>{
        setMintProcess(false)
        if(data.type=="success"){
          props.history.push({
            pathname: '/mint',
            search: '',
            params: { mintData: data }
          })
        }else{
          setError(data.message);
        }
      })
    }else{
      props.mintNftsWithToken(NFTProjectInfo.nft_eth.token_address,mintNumber,user.data.eth_address,(data)=>{
        setMintProcess(false)
        if(data.type=="success"){
          props.history.push({
            pathname: '/mint',
            search: '',
            params: { mintData: data }
          })
        }else{
          setError(data.message);
        }
      })
    }
  }


  return (
    // defining a fat arrow functio
    <>
      <div className="container homePage pt-5">
        <div className="row justify-content-center">
          <div className="col-lg-4 pt-5">
            <img src={NFTProjectInfo.home.image} alt="" className='img-fluid'/>
          </div>
          
          <div className="col-lg-6 pt-4">
            {user.login &&
              <h3 className='heading'><i className="fa-solid fa-person-snowboarding"></i>  {nftInfo.maxSupply}  NFTs</h3>
            }

            <h1 className="title">{NFTProjectInfo.home.title}</h1>
            <p className="">
              {NFTProjectInfo.home.details}
            </p>

              
              {user.login ?
              <div className="card mt-3 input-card">
                <div className="card-body">
                  <div className='d-flex align-items-center mb-3'>
                    <img src={NFTProjectInfo.nft_eth.eth_icon} className="me-2 img-fluid card-title" alt="" />
                    <h5>{price}  {NFTProjectInfo.nft_eth.eth_name} PER NFT</h5>
                  </div>
                    <div className="row d-flex align-items-center">
                      <div className="col-md-6">
                        <input className={mintNumber > NFTProjectInfo.nft_eth.nft_max_mint ? "form-control ps-3 err_box" : "form-control ps-3"} type="number" value={mintNumber} placeholder={`Number of ${NFTProjectInfo.nft_short_name} (max ${NFTProjectInfo.nft_eth.nft_max_mint})`} min="1" max="20" onChange={(e) => setMintCount(e)} disabled={mintProcess} />
                      </div>
                      <div className="col-md-6">
                      <button className="btn btn-primary mint_btn w-100 align-items-center" onClick={()=>{mintTokenBtn('eth')}} disabled={mintNumber > NFTProjectInfo.nft_eth.nft_max_mint || mintProcess}>{mintProcess ? "Minting..." : <>Mint {NFTProjectInfo.nft_short_name} <i className="fa fa-arrow-right"></i></>}</button>
                      </div>
                    </div>

                    {mintNumber > NFTProjectInfo.nft_eth.nft_max_mint &&
                      <p id="error">You can mint a maximum of {NFTProjectInfo.nft_eth.nft_max_mint} {NFTProjectInfo.nft_short_name} at a time.</p>
                    }
                    {error != "" &&
                      <p id="error">{error}</p>
                    }
                    
                 </div>
              </div>
              :
              <>
              <div className='metamask-card d-flex flex-column align-items-center'>
                <button className="btn d-flex align-items-center" onClick={() => { props.userLogin() }}><img src="/assets/images/metamask-fox-logo.svg" width="" className='me-2'/> Connect With Metamask</button>
                {user.message != "" &&
                  <p id="error">{user.message}</p>
                }
              </div>
               
              </>
  }
          </div>  
        </div>
      </div>
    </>
  )
}




const mapStateToProps = (state) => ({
  user: state.user,
  nftInfo: state.nftInfo,
  mintInfo: state.mintInfo,
})

const mapDispatchToProps = {
  userLogin,mintNftsWithEth,mintNftsWithToken,resetMint
}

export default connect(mapStateToProps, mapDispatchToProps)(Homepage)
