import React, {Suspense,StrictMode} from "react";

import { Provider } from "react-redux";
import configureStore from "./services/configureStore";

import Routes from "./routes";
import './styles/style.css'

const store = configureStore();

function App() {
  return (
    <StrictMode>
      <Provider store={store}>
        <Suspense fallback={null}>
            <Routes/>
        </Suspense>
      </Provider>
    </StrictMode>
  );
}

export default App;
