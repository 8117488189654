import detectEthereumProvider from '@metamask/detect-provider';
import Web3 from 'web3';
import * as constant from "../services/constants"

import ERC721 from "../services/abi/ERC721"
import ERC20 from "../services/abi/ERC20"


import chainObject  from "../services/constants/chains"

import NFTProjectInfo from '../services/constants/projects/nft';

import {registerUser} from "../services/actions";


const BigNumber = require('bignumber.js');




const ethereum = window.ethereum;

const PerPage = 12;

// for ethereum interaction function
export const web3 = new Web3();

export async function connect(dispatch){
  if (typeof window.ethereum === 'undefined') {
    window.open("https://metamask.app.link/dapp/"+constant.DOMAIN_NAME.replace("https://", "").replace("http://", "")+"/dashboard?login=metamask");
    return
  } 
  ethereum.request({ method: 'eth_requestAccounts' }).then(accounts => {

    ethereum.on('accountsChanged', function (accounts) {
      window.location.reload();
    });
  
    ethereum.on('chainChanged', function (accounts) {
      window.location.reload();
    });
  
    
    if (ethereum.isMetaMask && ethereum.selectedAddress != null) { 
        detectEthereumProvider().then(provider=>{
          web3.setProvider(provider);
          web3.eth.net.getNetworkType().then(async(network_type) => {
            let network = {}
            network['type'] = network_type;
            network['chainId'] = await ethereum.request({ method: 'eth_chainId' });
            network['networkVersion'] = await ethereum.request({ method: 'net_version' });
            // if(isChainAllow(network['chainId'])){ 
            if(parseInt(NFTProjectInfo.nft_eth.chainId) === parseInt(network['chainId'])){ 
              // call register api
              try{
                let apiData = await registerUser({eth_address:ethereum.selectedAddress});
                console.log("apiData",apiData);
              }catch(e){
                console.log(e);
              }


              dispatch({type:"LOGIN_SUCCESS",data:{eth_address:ethereum.selectedAddress},network:network});
              let nftInfo = await getNftInfo(ethereum.selectedAddress);
              dispatch({type:"NFT_INFO",data:nftInfo});
            }else{
              let chainId = NFTProjectInfo.nft_eth.chainId;
              walletSwitchEthereumChain(chainId,(data)=>{
                if(data.type =='error'){
                  dispatch({type:"LOGIN_NETWORK_ERROR",data:{eth_address:ethereum.selectedAddress},network:network});
                }
              })
             
            }
            
          })
        });
    }else{
      dispatch({type:"LOGIN_ERROR",message: "Some error occurred!"});
    } 
  }).catch((error)=>{
    dispatch({type:"LOGIN_ERROR",message: error.message});
  });
}

export async function walletSwitchEthereumChain(chainID,callback){
  try {
    await ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: (getHaxChainId(chainID)) }],
    });
    callback({type:"success",message:"network get switched to "+chainID});
  } catch (switchError) {
    if (switchError.code === 4902) {
      walletAddEthereumChain(chainID,(data)=>{
        if(data.type=='success'){
          walletSwitchEthereumChain(chainID,callback);
        }else{
          callback(data);
        }
      });
    }else{
      callback({type:"error",message:switchError.message});
    }
  }
  
}

export function getHaxChainId(id){
  return "0x"+(id).toString(16)
}

export async function walletAddEthereumChain(chainId,callback){
  chainId = parseInt(chainId);
  let chain = chainObject.find(v=> v.chainId == chainId);
  let object = { }
  if(!chain){
    callback({type:"error",message:"network chain - "+chainId+" not found!"});
    return 
  }else{
    object={ 
      chainId: getHaxChainId(chain.chainId), // A 0x-prefixed hexadecimal string
      chainName: chain.name,
      nativeCurrency: chain.nativeCurrency,
      rpcUrls: chain.rpc,
      blockExplorerUrls: (chain.explorers.length >0 ? chain.explorers.map((e)=>e.url) : null),
      iconUrls: []
    }
  }
  try {
    await ethereum.request({
      method: 'wallet_addEthereumChain',
      params: [object],
    });
    callback({type:"success",message:"wallet added to chain"});
  } catch (addError) {
    callback({type:"error",message:addError.message});
  }

}



export  async function getNftInfo(eth_address) {
  // const tokenContract = new web3.eth.Contract(ERC20, NFTProjectInfo.nft_eth.token_address);
  const nftContract = new web3.eth.Contract(ERC721, NFTProjectInfo.nft_eth.nft_address);
  let  maxSupply = await nftContract.methods.maxSupply().call();
  let  totalSupply = await nftContract.methods.totalSupply().call();
  let leftSupply =  maxSupply - totalSupply;
  leftSupply = leftSupply >=0 ? leftSupply : 0;

  let  price = await nftContract.methods.eth_price().call();
  let  myNftsCount = await nftContract.methods.balanceOf(eth_address).call();
  // let  myTokenBalance = await tokenContract.methods.balanceOf(eth_address).call();

  return {maxSupply,totalSupply,leftSupply,price,myNftsCount,myTokenBalance:0}
}
 

export  async function mintWithEth(count,eth_address) {
  try{
    const nftContract = new web3.eth.Contract(ERC721, NFTProjectInfo.nft_eth.nft_address);
    let  price = await nftContract.methods.eth_price().call();
    price = count*price; 
    let aTx = await nftContract.methods.mintWithEth(count).send({from:eth_address,value:price});
    let tokenIds =[];
    if(Array.isArray(aTx.events['Transfer'])){
      for(let i=0; i<aTx.events['Transfer'].length;i++){
        let returnValues = aTx.events['Transfer'][i].returnValues;
        tokenIds.push(returnValues['tokenId']);
      }
    }else{
       let returnValues = aTx.events['Transfer'].returnValues;
        tokenIds.push(returnValues['tokenId']);
    }
    let finalTx;
    do {
      finalTx = await web3.eth.getTransactionReceipt(aTx.transactionHash);
    }while (!finalTx.status);
    
    return {type:"success",data:{finalTx,tokenIds},message:""}
  }catch(e){
    return {type:"error",data:{},message:e.message};
  }
}

export  async function mintWithToken(address,count,eth_address) {
  try{
    const tokenContract = new web3.eth.Contract(ERC20, address);
    const nftContract = new web3.eth.Contract(ERC721, NFTProjectInfo.nft_eth.nft_address);
    let  price = await nftContract.methods.tokenPrice(address).call();
    // console.log("price",price);
    
    let b_amount = new BigNumber(price)
    // console.log("b_amount",b_amount);
    price = b_amount.multipliedBy(new BigNumber(count))
    // console.log("price",price);
  
    let allowAmount = await tokenContract.methods.allowance(eth_address,NFTProjectInfo.nft_eth.nft_address).call();
    allowAmount = new BigNumber(allowAmount)
    // console.log("condition",price.gt(allowAmount));

    // console.log("price",price.toString());
    // console.log("price",allowAmount.toString());
    if(price.gt(allowAmount)){
      // for making unlimited approval.
      let maxAllow = '115792089237316195423570985008687907853269984665640564039457584007913129639935'; //(2^256 - 1 )

      await tokenContract.methods.approve(NFTProjectInfo.nft_eth.nft_address,maxAllow).send({from:eth_address});
      // await tokenContract.methods.approve(NFTProjectInfo.nft_eth.nft_address,price).send({from:eth_address});
    }  



    let aTx = await nftContract.methods.mintWithToken(count,address).send({from:eth_address});
    let tokenIds =[];
    if(Array.isArray(aTx.events['Transfer'])){
      for(let i=0; i<aTx.events['Transfer'].length;i++){
        let returnValues = aTx.events['Transfer'][i].returnValues;
        tokenIds.push(returnValues['tokenId']);
      }
    }else{
       let returnValues = aTx.events['Transfer'].returnValues;
        tokenIds.push(returnValues['tokenId']);
    }
    let finalTx;
    do {
      finalTx = await web3.eth.getTransactionReceipt(aTx.transactionHash);
    }while (!finalTx.status);
    
    return {type:"success",data:{finalTx,tokenIds},message:""}
  }catch(e){
    return {type:"error",data:{},message:e.message};
  }
}

export  async function getTokenInfo(tokenIds) {
  const nftContract = new web3.eth.Contract(ERC721, NFTProjectInfo.nft_eth.nft_address);
  let tokenData = [];
  for(let i=0;i<tokenIds.length;i++){
    let url = await nftContract.methods.tokenURI(tokenIds[i]).call();
    let tokenInfo = await fetch(url).then(response => response.json());
    tokenData.push(tokenInfo);
  }
  return tokenData;
}

export  async function loadMyNfts(eth_address,from=0) {
  const nftContract = new web3.eth.Contract(ERC721, NFTProjectInfo.nft_eth.nft_address);
  let  myNftsCount = await nftContract.methods.balanceOf(eth_address).call();
  let tokenIds =[];
  from = from*PerPage;
  let to = from+PerPage;
  to = to>myNftsCount ? myNftsCount : to;

  for(let i=from;i<to;i++){
    let tokenId = await nftContract.methods.tokenOfOwnerByIndex(eth_address,i).call();
    tokenIds.push(tokenId);
  }
  return await getTokenInfo(tokenIds);
}

export  async function loadAllNfts(from=0) {
  const nftContract = new web3.eth.Contract(ERC721, NFTProjectInfo.nft_eth.nft_address);
  let  myNftsCount = await nftContract.methods.totalSupply().call();

  from = from*PerPage;
  
  let to = from+PerPage;
  to = to>myNftsCount ? myNftsCount : to;

  let tokenIds =[];
  for(let i=from;i<to;i++){
    // let tokenId = await nftContract.methods.tokenByIndex(i).call();
    // tokenIds.push(tokenId);
    tokenIds.push(i+1);
  }
  return await getTokenInfo(tokenIds);
}



export function shortEthAddress(address){
  let addr = web3.utils.toChecksumAddress(address);
  return addr.substr(0,6)+"..."+addr.substr(addr.length-4,4);
}
