const nftProjectData = {
  name: 'Isbjorn',
  nft_name: 'Isbjorn',
  nft_short_name: 'Isbjorn',
  logo: '/assets/images/theme1/isbjorn_logo-500.png',
  favicon: '/assets/images/theme1/fav_icon.png',
  about_us_url: 'NFT',
  discord_url: 'NFT',
  mint_nft_text: 'Mint NFT',
  home:{
    image:'/assets/images/theme1/base.png',
    title:"Grab your gear, mint now!",
    details:"Get ready for the Rush. Our Skis & Snowboards collection is a blue-chip collection exclusive to Avalanche. Gear up and take to the slopes in our new multiplayer racing game. Those who mint will gain access to our exclusive members lounge, and receive a Loot & $IGGY airdrop! Go mint your gear today!",
  },
  max_nft_mint: 20,
  nft_eth:{
    chainId: process.env.REACT_APP_CHAIN_ID,
    nft_max_supply: parseInt(process.env.REACT_APP_MAX_SUPPLY),
    nft_max_mint: parseInt(process.env.REACT_APP_MAX_MINT),
    nft_address:process.env.REACT_APP_NFT_ADDRESS,
    token_address:process.env.REACT_APP_TOKEN_ADDRESS,
    eth_icon:"/assets/images/theme1/avax.svg",
    eth_name:"AVAX",
  },
  gallery:{
    title:'Skis and Snowboards Gallery',
    details: 'Discover our collection.',
    nft_per_page: 12,
    nft_img1: "/assets/images/theme1/base.png",
    nft_img2: "/assets/images/theme1/base.png",
    nft_stake_url: "",
    nft_stake_text: "",
  },
  twitter :{
    message:"I minted an NFT from @IsbjornDAO. Take a look! Order yours at isbjorn.co.nz",
    hashtags:"AvaxNFTRush",
  },
  stake:{
    status:false,
    details:"Stake your NFTs at PolyientX and start earning $IGGY",
    url:"https://app.polyientx.com/"
  },
  mintSuccess:{
    title:"Welcome To The Club",
    details:"Congrats! You're a bonafide member of IGGY. View your Isbjorn in the Gallery, share them on Twitter or start earning $IGGY for more rewards!"
  }

}

export default nftProjectData;
