import { createStore, applyMiddleware } from "redux";
import thunk from 'redux-thunk';

import rootReducer from "./reducers";

// import { composeWithDevTools } from 'redux-devtools-extension';

const initialState = { };


export default function configureStore() {
  const store = createStore(
    rootReducer,
    initialState,
    (
      applyMiddleware(
        thunk,
      )
    )
  );
  return store;
}
