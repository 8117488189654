import React from 'react'
import NFTProjectInfo from '../../services/constants/projects/nft';

function Stake(props) {
  return (
    <>
    {NFTProjectInfo.stake.status ? 
        <div className="all-nft row justify-content-center">
              <div className="col-lg-6 col-md-12 col-11 ">
                <div className="staking-card row py-3">
                  <div className="col-6">
                      <h3 className='stake-nft'>{NFTProjectInfo.stake.details}</h3>
                  </div>
                  <div className="col-6 d-flex justify-content-end align-items-center">
                      <a href={NFTProjectInfo.stake.url} className="btn me-2" target="_blank">Stake Now</a>
                  </div>
                </div>
              </div>
            </div>
    :
    null
    }
    </>

  )
}

export default Stake