import React from 'react'
import {Button,Modal} from 'react-bootstrap'

import * as constants from "../../services/constants"
import NFTProjectInfo from '../../services/constants/projects/nft';


function Modalcard(props) {
  const {nft}=props;

  let data = [
    {
      attr_type: "Rarity",
      attr_value: nft.attributes.find((v)=>v.trait_type=="Rarity").value
    },
  ]

  let property_data = data.map((key, index) => {
    return (
        <div className='row bg-white' key={key.attr_type}>
            <div className="col-md-4">
                <h5 className='card-body-text'>{key.attr_type}</h5>
            </div>
            <div className="col-md-4">
                <h5 className='card-body-text' style={{color: "#2D5255"}}>{key.attr_value}</h5>
            </div>
        </div>
    )
  })

  const shareTwitter = ()=>{
    window.open("https://twitter.com/share?text="+NFTProjectInfo.twitter.message+"&url="+constants.DOMAIN_NAME+'?nft='+nft.id+"&hashtags="+NFTProjectInfo.twitter.hashtags);
  }
  return (
    <>
    <Modal show={props.show} onHide={props.handleCloseAlert} backdrop={true} backdropClassName="modal-backdrop foo-modal-backdrop in" dialogClassName="modal-alert">
        <Modal.Body>
            <div className="card modal-card">
                <img src={nft.image} className="card-img-top" alt={nft.name}/>
                <div className="card-body modal-card-body">
                    <h5 className="card-title text-center nft-card-title">{nft.name}</h5>
                    {/* <p className="card-text text-center nft-card-subtitle">{nft.attributes.find((v)=>v.trait_type=="Rarity").value}</p> */}
                </div>
                <div className="container pt-3">
                    <br/>
                        {property_data}
                        <br/>

                    <div className="row my-3">
                     <button className="w-100 btn btn-light bg-white  w-100 align-items-center share_btn  px-3 py-2 rounded-0 border  border-secondary display-3 share-btn mx-auto"  onClick={shareTwitter}>Share
                      On Twitter <i className="fa fa-arrow-right"></i></button>
                    </div>
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="secondary" onClick={()=>{props.handleCloseAlert()}} className="btn">Close</Button>
        </Modal.Footer>
    </Modal>
    </>
  )
}

export default Modalcard