import React,{useEffect} from 'react'
import {Route,BrowserRouter, Redirect,Switch} from "react-router-dom";
import {useLocation, withRouter} from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'


import {LayoutOne} from '../layouts'
import Homepage from '../pages/Homepage'
import Gallery from '../pages/Gallery';
import MintSuccess from '../pages/Mint';

function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
      window.scrollTo(0, 0);
  }, [pathname]);
  return props.children
}
const ScrollToTop = withRouter(_ScrollToTop)

function RouteWrapper({ component: Component, layout: Layout, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout {...props} login={rest.login}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

export const Routes = (props) => {
  const { user } = props
  return (
    <BrowserRouter>
    <ScrollToTop>
      {user.login ?
        <Switch>
          <RouteWrapper exact path="/" component={Homepage} layout={LayoutOne} />
          <RouteWrapper exact path="/gallery" component={Gallery} layout={LayoutOne} />
          <RouteWrapper exact path="/mint" component={MintSuccess} layout={LayoutOne} />
          <Redirect to="/" />
        </Switch>
        :
        <Switch>
          <RouteWrapper exact path="/" component={Homepage} layout={LayoutOne} />
          <Redirect to="/" />
        </Switch>

      }
    </ScrollToTop>
    </BrowserRouter>
  )
}

Routes.propTypes = {
  user: PropTypes.object
}

const mapStateToProps = (state) => ({
  user: state.user,
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Routes)
