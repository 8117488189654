export function  userReducer(state = {login:false,data:false,network:"",message:""}, action){
  switch (action.type) {
    case "LOGIN_RESET":
      return {login:false,data:false,network:"",message:""}
    case "LOGIN_SUCCESS":
      return {login:true,data:action.data,network:action.network,message:""}
    case "LOGIN_ERROR":
      return {login:false,data:false,network:"",message:action.message}  
    case "LOGIN_NETWORK_ERROR":
      return {...state,network:false,message:"Please check your network!"}  
    default:
      return state;
  }
}
