import React,{useState} from 'react'
import { Link,withRouter } from 'react-router-dom'
import { connect } from "react-redux";


import {Navbar,Container,Nav} from 'react-bootstrap'
import NFTProjectInfo from '../../services/constants/projects/nft';
import {shortEthAddress} from "../../utils/ethereum"


function Header(props) {
  const {user} = props;
  const [nav,showNav] = useState(false);
  let pathName = props.location.pathname;

  return (
    <>
      <Navbar bg="light" expand="lg" className="navbar">
        <Container>
        <Link to="/" className="navbar-brand" href="#"><img src={NFTProjectInfo.logo} alt={NFTProjectInfo.name} height="47"/></Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto navbar-content d-flex align-items-center">
              <Nav.Link className="nav-link navbar-content-link" href="https://twitter.com/isbjorndao" target="_blank">Twitter</Nav.Link>
              <Nav.Link className="nav-link navbar-content-link" href="https://discord.com/invite/K4z7xxFVGc"  target="_blank">Discord</Nav.Link>
              <Nav.Link className="nav-link navbar-content-link" href="http://t.me/isbjorn_public" target="_blank">Telegram</Nav.Link>

              <Link to="/gallery" className={pathName=="/gallery" ? "nav-link navbar-content-link active1":"nav-link navbar-content-link"}  id='gallery'>Gallery</Link>

              <Link to="/" className={(pathName=="/" || pathName=="/mint") ? "nav-link navbar-content-link active1":"nav-link navbar-content-link"}>Mint NFTs</Link>

              {user.login && 
                <Nav.Link className="navbar-content-link d-flex align-items-center" href="#">
                  <h6>{shortEthAddress(user.data.eth_address)}</h6>
                  <img src="/assets/images/profile.svg" className='ms-1' alt="" width="40" height="40"/>
                </Nav.Link>
              }

            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}



const mapStateToProps = (state) => ({
  user: state.user,
  nftInfo: state.nftInfo,
})
const mapDispatchToProps = {
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));