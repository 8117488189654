import {connect,loadMyNfts,loadAllNfts,mintWithEth,mintWithToken,getNftInfo} from "../../utils/ethereum";
export function userLogin(){
  return (dispatch)=>{
    connect(dispatch);
  }
}

export  function baseNfts(eth_address){
  return async(dispatch)=>{
    let nftInfo = await getNftInfo(eth_address);
    dispatch({type:"NFT_INFO",data:nftInfo});
  }
}

export function loadMyNftsData(eth_address,from=0){
  return async(dispatch)=>{
    if(from==0){
      dispatch({type:"MY_NFTS_RESET"});
    }else{
      dispatch({type:"MY_NFTS_PENDING"});
    }
     let myNfts = await loadMyNfts(eth_address,from);
     dispatch({type:"MY_NFTS_SUCCESS",data:myNfts});
  }
}

export function loadAllNftsData(from=0){
  return async(dispatch)=>{
    if(from==0){
      dispatch({type:"ALL_NFTS_RESET"});
    }else{
      dispatch({type:"ALL_NFTS_PENDING"});
    }
    let allNfts = await loadAllNfts(from);
    dispatch({type:"ALL_NFTS_SUCCESS",data:allNfts});
 }
}

export function resetMint(){
  return async(dispatch)=>{
    dispatch({type:"MY_NFT_MINT_RESET"});
  }
}
export function mintNftsWithEth(count,eth_address,callback){
  return async(dispatch)=>{
    let mintInfo = await mintWithEth(count,eth_address);
    if(mintInfo.type =='success'){
      dispatch({type:"MY_NFT_MINT_SUCCESS",data:mintInfo.data,message:mintInfo.message});
      baseNfts(eth_address)(dispatch);
    }else{
      dispatch({type:"MY_NFT_MINT_ERROR",data:mintInfo.data,message:mintInfo.message});
    }
    callback(mintInfo);
 }
}

export function mintNftsWithToken(address,count,eth_address,callback){
  return async(dispatch)=>{
    let mintInfo = await mintWithToken(address,count,eth_address);
    if(mintInfo.type =='success'){
      dispatch({type:"MY_NFT_MINT_SUCCESS",data:mintInfo.data,message:mintInfo.message});
      baseNfts(eth_address)(dispatch);
    }else{
      dispatch({type:"MY_NFT_MINT_ERROR",data:mintInfo.data,message:mintInfo.message});
    }
    callback(mintInfo);
 }
}