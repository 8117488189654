import React,{useEffect,useState} from 'react'
import { Link,withRouter } from 'react-router-dom'

import PropTypes from 'prop-types'
import { connect } from "react-redux";

import {getTokenInfo} from "../../utils/ethereum";

import * as constants from "../../services/constants"
import NFTProjectInfo from '../../services/constants/projects/nft';


function MintSuccess(props) {

    const {mintInfo}=props;
  //finalTx tokenIds
  const [tokenInfo,setTokenInfo]=useState([]);
  const [showToken,setShowToken]=useState(false);
  useEffect(async()=>{
    if(mintInfo.type =='success' && mintInfo.data.tokenIds.length>0){
      let tokenData = await getTokenInfo(mintInfo.data.tokenIds);
      setTokenInfo(tokenData);
      if(tokenData.length > 0){
        setShowToken(0)
      }
    }else{
      props.history.push({ pathname: '/'});
    }
  },[mintInfo.data.tokenIds])

  const shareTwitter = ()=>{
    window.open("https://twitter.com/share?text="+NFTProjectInfo.twitter.message+"&url="+constants.DOMAIN_NAME+"&hashtags="+NFTProjectInfo.twitter.hashtags);
  }

  return (
    <>
        <div className="container success-screen py-5">
            <div className="row justify-content-center success">
                <div className="col-lg-5">
                   <div className="row justify-content-center pt-5 success-bg">
                       <div className="col-lg-7">


                       {showToken===false ?
                       <div className="card">
                            <img src="/assets/images/no-image.svg" className="card-img-top" alt="..."/>
                            <div className="card-body">
                                <h5 className="card-title">Loading...</h5>
                                <a href="#"></a>
                            </div>
                        </div>
                        :
                        <div className="card">
                            <img src={tokenInfo[showToken].image} className="card-img-top" alt="..." key={showToken}/>
                            <div className="card-body">
                                <h5 className="card-title">{tokenInfo[showToken].name} </h5>
                                <Link to="/gallery" className="green_text text-start">View In Gallery</Link>
                            </div>
                        </div>
                        }

                            
                        {mintInfo.data.tokenIds.length>1 &&
                            <div className='toggle-arrow mt-4'>
                            <button className="btn btn-link" disabled={showToken==0} onClick={()=>{setShowToken(showToken-1)}}>     <i className="fa-solid fa-arrow-left"></i>
                            </button>
                            <button className="btn btn-link" disabled={showToken==(tokenInfo.length-1)} onClick={()=>{setShowToken(showToken+1)}}>
                                <i className="fa-solid fa-arrow-right ms-4"></i>
                            </button>
                            </div>
      }
                       </div>
                   </div>
                </div>
                <div className="col-lg-5 pt-4 success-text">
                    <h1>{NFTProjectInfo.mintSuccess.title}</h1>
                    <p className="pt-2">{NFTProjectInfo.mintSuccess.details}</p>
                    <div className='d-flex mt-5'>
                    <Link to="/"  className="btn">Mint Another</Link>
                        <button className="share ms-3" onClick={shareTwitter}>Share on Twitter <i className="fa-solid fa-arrow-right"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}



const mapStateToProps = (state) => ({
    user: state.user,
    mintInfo: state.mintInfo,
  })
  
const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MintSuccess))