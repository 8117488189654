import React, { useState,useEffect } from 'react'
import { connect } from "react-redux";
import { Link } from 'react-router-dom'

import {Tabs,Tab} from 'react-bootstrap'
import Stake from '../../components/Stake'
import NftCard from '../../components/NftCard'

import {loadMyNftsData,loadAllNftsData} from "../../services/actions"


import NFTProjectInfo from '../../services/constants/projects/nft';

import ModalCard from '../../components/NftCard/modal';


function Gallery(props) {
 const {user,nftInfo,myNfts,allNfts} = props;
  const [showNftCard, setShowNftCard] = useState(false);
  const [currentTab, setCurrentTab] = useState('my_nft');

  const [myFrom, setMyFrom] = useState(0);
  const [allFrom, setAllFrom] = useState(0);


  useEffect(()=>{
    loadNfts(currentTab);
  },[]);

  const changeTab=(val)=>{
    setCurrentTab(val);
    loadNfts(val);
  }

  const loadNfts =(tab_name)=>{
    if(tab_name == 'my_nft'){
      if(myNfts.type=="" || myNfts.type=="success"){
        props.loadMyNftsData(user.data.eth_address,myFrom);
        setMyFrom(myFrom+1)
      }
    }else{
      if(allNfts.type=="" || allNfts.type=="success"){
        props.loadAllNftsData(allFrom);
        setAllFrom(allFrom+1);
      }
    }
  }

  return (
    <>
      {showNftCard ?
        <>
           <ModalCard show={showNftCard!=false} nft={showNftCard} handleCloseAlert={() => { setShowNftCard(false); }} />
        </>
        : ""}

      <section className="gallery">
        <div className="container py-5">
          <div className="row py-5">
            <div className="col-lg-5 pt-3">
              <h3 className='heading'><i className="fa-solid fa-person-snowboarding"></i>  {nftInfo.maxSupply}  NFTs</h3>
              <h1 className='title'>{NFTProjectInfo.gallery.title}</h1>
              <p className='sub-heading'>{NFTProjectInfo.gallery.details}</p>
            </div>
            <div className="col-lg-7">
              <div className="row d-flex justify-content-center">
                <div className="col-md-6 ">
                  <img src={NFTProjectInfo.gallery.nft_img1} alt="" className='img-fluid'/>
               
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="tabs my-5">
        <div className="container">
          <div className="row">
          <Tabs defaultActiveKey="my_nft" id="uncontrolled-tab-example" className="mb-3" activeKey={currentTab} onSelect={changeTab}>
            <Tab eventKey="all_nfts" title={`All NFTs (${nftInfo.totalSupply})`}>
              <Stake/>
              <div className="all-nft container py-3">
                  <div className="row">
                  {(allNfts.type=='success' && allNfts.data.length == 0)&&
                    <div className='d-flex justify-content-center mt-4 align-items-center'>
                    <h3 className='text-center pt-3'>No NFTs? No worries.</h3>
                    <Link to="/" className='ms-3 mint'>Mint Now</Link>
                    </div>
                  }
                    {allNfts.type=='new_request'?
                    <div className="d-flex justify-content-center align-self-center">
                      <h3  className="pt-5"> Loading...</h3>
                      <br/>
                      <br/>
                     </div>
                     :
                     <>
                     {allNfts.data.map((nft) => <NftCard nft={nft} viewNft={(v)=>setShowNftCard(v)}/>)}
                     </>
                    }

                  {((allNfts.type=='success' || allNfts.type=='request') && allNfts.data.length < nftInfo.totalSupply) &&
                      <div className="d-flex justify-content-center align-self-center pt-5">
                          <button onClick={()=>loadNfts(currentTab)} className="btn btn-primary mint_btn align-items-center" disabled={allNfts.type=='request'}> {allNfts.type=='request' ? "Loading..." : "Load More"}</button>
                      </div>
                    }
                  </div>
              </div>
            </Tab>
            <Tab eventKey="my_nft" title={`Your NFTs (${nftInfo.myNftsCount})`}>
              <Stake/>
              <div className="all-nft container py-3">
                  <div className="row">
              {(myNfts.type=='success' && myNfts.data.length == 0)&&
                <div className='d-flex justify-content-center mt-4 align-items-center'>
                <h3 className='text-center pt-3'>No NFTs? No worries.</h3>
                <Link to="/" className='ms-3 mint'>Mint Now</Link>
                </div>
               }

              {myNfts.type=='new_request'?
                  <div className="d-flex justify-content-center align-self-center">
                  <h3  className="pt-5"> Loading...</h3>
                  <br/>
                  <br/>
                </div>
                :
                <>
                 {myNfts.data.map((nft) => <NftCard nft={nft} viewNft={(v)=>setShowNftCard(v)}/>)}
                </>
              }


              {((myNfts.type=='success' || myNfts.type=='request') && myNfts.data.length < nftInfo.myNftsCount) &&
                    <div className="d-flex justify-content-center align-self-center pt-5">
                          <button onClick={()=>loadNfts(currentTab)} className="btn btn-primary mint_btn align-items-center" disabled={myNfts.type=='request'}> {myNfts.type=='request' ? "Loading..." : "Load More"}</button>
                      </div>
                  }

              </div>
              </div>
            </Tab>
          </Tabs>
          </div>
        </div>
      </section>
    </>
  )
}


const mapStateToProps = (state) => ({
  user: state.user,
  nftInfo: state.nftInfo,
  myNfts: state.myNfts,
  allNfts: state.allNfts,
})

const mapDispatchToProps = {
  loadMyNftsData,loadAllNftsData
}
export default connect(mapStateToProps, mapDispatchToProps)(Gallery)

